import { useState, useEffect, useRef } from 'react';
import { AmenitiesType, Filter } from './components/Filter';
import { Icons } from '../../components/Icons';
import { Header } from '../../components/Header';
import * as S from './styles';
import { Location, dataMap } from '../../components/Map';
import { useAnyProvider } from '../../hooks/Context';
import { useSpaProvider } from '../../hooks/spaRouterContext';
import { NavLink, useSearchParams } from 'react-router-dom';
import { CardResult } from '../../components/CardResult';
import fetchAxios from 'services/http';
import { Button } from 'components/Button';
import { ItensLoading } from './components/ItensLoading';
import { useScreenWidth as useScreen } from '../../utils/useScreenWidth';
import { HeaderMobile } from 'components/HeaderMobile';
import { LoadingMobile } from 'components/LoadingMobile';
import { WhatsappButton } from 'components/WhatsappButton';
import dayjs from 'dayjs';
import { ToogleValueFee } from './components/ToogleValueFee';
import { SkeletonResults } from './components/Skeleton';
import { useSessionStorage } from 'context/sessionStorage';
import { useGuestStore } from 'components/Header/components/useGuestStorage';

const noResult = require('assets/svg/NoResults.svg').default;

export const Results = () => {
  const [closed, setClosed] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [buildingsMap, setBuildingsMap] = useState<dataMap[]>([]);
  const [shouldFocus, setShouldFocus] = useState(false);
  const [showTotalWithTax, setShowTotalWithTax] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [hasMore, setHasMore] = useState(true);
  const [totalProperties, setTotalProperties] = useState<number | null>(null);
  const [maxDisplayCount, setMaxDisplayCount] = useState(false);
  const [isFetching, setIsFetch] = useState(false);
  const [noProperties, setNoProperties] = useState(false);

  const [shouldFetchNewData, setShouldFetchNewData] = useState(false);

  const [date, setDate] = useState<Date[] | null>([]);

  const { setExpanded, } = useSpaProvider();
  const { saveToSessionStorage, getFromSessionStorage, } = useSessionStorage()

  const [addressSearch, setAddressSearch] = useState('');
  const latestRequest = useRef<number>(0);

  const [properties, setProperties] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [activeAmenitiesFilter, setActiveAmenitiesFilter] = useState<AmenitiesType[]>([])

  const arrowIcon = require('assets/svg/arrowNotice.svg').default;
  const arrowClosedModal = require('assets/svg/arrowLeft.svg').default;
  const arrowOpen = require('assets/svg/arrowRight.svg').default;
  const filterIcon = require('assets/svg/filterIcon.svg').default;

  const icon = <Icons icon={arrowClosedModal} height={24} width={17} />;
  const iconclosed = <Icons icon={arrowOpen} height={24} width={17} />;

  const { searchValue, amenities, filters, searchType, setAmenities, setSearchType, setSearchValue, autoCompletSearch } = useAnyProvider();
  const resultsListRef = useRef<HTMLDivElement>(null);

  const prevAmenitiesListingRef = useRef<string[]>([]);
  const prevAmenitiesCondominiumRef = useRef<string[]>([]);

  const [isGetPropertiesLoading, setIsGetPropertiesLoading] = useState(true);

  document.title = `Encontre o seu Yogha | Yogha`;

  const { totalGuests } = useGuestStore()

  useEffect(() => {
    setAmenities([]);
    latestRequest.current = 0;
    // setHasMore(true);
    // getProperties(1);
  }, [addressSearch]);

  const breakpoint = useScreen();

  useEffect(() => {
    getProperties(1);
    setPage(1);
  }, [autoCompletSearch, searchType, date]);

  const [isLoading, setIsLoading] = useState(false);

  const getCheckOut = (date: any) => {
    return date?.[1] ? dayjs(date[1]).format('YYYY-MM-DD') : null;
  };

  const getCheckIn = (date: any) => {
    return date?.[0] ? dayjs(date[0]).format('YYYY-MM-DD') : null;
  };

  useEffect(() => {
    const amenitiesData = getFromSessionStorage('amenities')
    const getAmenities = async () => {
      try {
        const { data } = await fetchAxios.get('amenities');
        const newAmenities = data.data.map((item: any) => {
          return {
            id: item.id,
            name: item.name,
            check: false,
            type: item.type
          }
        })
        const activeAmenities = newAmenities.filter((item: any) => item.check === true);
        setActiveAmenitiesFilter(activeAmenities)
        setAmenities(newAmenities)
        saveToSessionStorage('amenities', newAmenities)
      } catch (error) {
        console.error(error)
      }
    }
    if (!!amenitiesData && amenitiesData.length > 0) {
      setAmenities(amenitiesData)
      const activeAmenities = amenitiesData.filter((item: any) => item.check === true);
      setActiveAmenitiesFilter(activeAmenities)
    } else {
      getAmenities()
    }
  }, [openFilter]);

  const getProperties = async (page = 1, search?: string) => {
    const validSearch = search !== undefined ? search : searchValue;

    if (page === 1) {
      setOpenFilter(false);
      if (resultsListRef.current) {
        resultsListRef.current.scrollTop = 0;
      }
      setIsLoading(true);
      setIsFetch(true);
    }

    const thisRequest = ++latestRequest.current;
    setIsLoading(true);
    try {
      const { data } = await fetchAxios.post(
        `accommodations`,
        {
          checkIn: getCheckIn(date) ? getCheckIn(date) : '',
          checkOut: getCheckOut(date) ? getCheckOut(date) : '',
          amenities: selectedAmenitiesListing ? selectedAmenitiesListing : [],
          building_amenities: selectedAmenitiesCondominium ? selectedAmenitiesCondominium : []
        },
        {
          params: {
            page: page,
            guest: totalGuests,
            [searchType]: validSearch,
            bedrooms: filters.bedroom,
            bathrooms: filters.bathroom,
            beds: filters.beds,
            type: searchType,
            limit: 12,
          }
        }
      );

      if (thisRequest !== latestRequest.current) return;
      const cleaningValueProperties = data.data.map((item: any) => {
        const filterService = item.services.filter((service: any) => service.name.includes('Limpeza Final'));
        return {
          ...item,
          cleaningFee: Number(filterService[0]?.value)
        }
      });
      setProperties(prevState => page === 1 ? cleaningValueProperties : [...prevState, ...cleaningValueProperties]);
      setTotalProperties(data.total);
      setHasMore(data.data.length > 0);
      setNoProperties(data.data.length === 0);

      if (thisRequest !== latestRequest.current) return;
      if (addressSearch !== searchValue) {
        search ? setAddressSearch(search) :
          setAddressSearch(searchValue);
      }
      setTotalProperties(data.total);
      const newData = data.data;

      // Se os dados estiverem vazios, defina hasMore como false

      if (newData.length === 0) {
        setNoProperties(true);
        setHasMore(false);
        setMaxDisplayCount(false); // <-- nova linha
      } else {
        setNoProperties(false);
        setMaxDisplayCount(true);
      }

      const response = await fetchAxios.get('/accommodations/buildings/list', {
        params: {
          home: true
        }
      });
      const buildings = response.data.data;

      const buildingTypeDataMap: dataMap[] = buildings.map((item: any) => {
        return {
          id_unit: item.id,
          name_unit: item.name,
          latitude: item.latitude,
          longitude: item.longitude,
          address: item.address,
          units: item.quantityAccommodations
        };
      });

      setBuildingsMap(buildingTypeDataMap);
      setIsFetch(false);

    } catch (error) {
      setNoProperties(true);
    } finally {
      setIsLoading(false);
      // setIsFetch(false);
      setIsGetPropertiesLoading(false);
      // setAddressSearch(searchValue);
    }
  };

  useEffect(() => {
    const searchParamsCheckIn = searchParams.get('check_in');
    const searchParamsCheckOut = searchParams.get('check_out');
    if (searchParamsCheckIn && searchParamsCheckOut) {
      setDate([dayjs(searchParamsCheckIn).toDate(), dayjs(searchParamsCheckOut).toDate()])
    }
    const searchQuery = searchParams.get('search');
    const searchQueryType = searchParams.get('type');
    if (searchQuery && searchQueryType) {
      setSearchValue(searchQuery);
      setSearchType(searchQueryType);
    }
  }, [searchParams])


  const handleSetAddress = (value: string) => {
    setAddressSearch(value);
  };

  const getSelectedAmenitiesIds = (amenities: AmenitiesType[] | null) => {
    if (!amenities || amenities.length === 0) {
      return [];
    }

    return amenities.filter((amenity) => amenity.check === true).map((amenity) => amenity.id);
  };

  const verifyHasFilterSelected = () => {
    if (resultsListRef !== null && resultsListRef.current) {
      resultsListRef.current.scrollTop = 0;
    }
    setPage(1);
    const amenitiesCondominium = amenities && amenities.filter((amenitie) => amenitie.check === true);
    if (amenitiesCondominium.length > 0) {
      getProperties();
    } else {
      getProperties();
      setPage(1);
    }
  };

  const amenitiesCondominium = amenities && amenities.filter((amenitie) => amenitie.type === 'property');
  const amenitiesListing = amenities && amenities.filter((amenitie) => amenitie.type === 'listing');

  const selectedAmenitiesListing = getSelectedAmenitiesIds(amenitiesListing);
  const selectedAmenitiesCondominium = getSelectedAmenitiesIds(amenitiesCondominium);

  const deepEqual = (arr1: any[], arr2: any[]): boolean => {
    if (arr1.length !== arr2.length) return false;

    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) return false;
    }

    return true;
  };

  useEffect(() => {
    const isAmenitiesListingChanged = !deepEqual(prevAmenitiesListingRef.current, selectedAmenitiesListing);
    const isAmenitiesCondominiumChanged = !deepEqual(prevAmenitiesCondominiumRef.current, selectedAmenitiesCondominium);

    if (isAmenitiesListingChanged || isAmenitiesCondominiumChanged) {
      setShouldFetchNewData(true);
    }
  }, [selectedAmenitiesListing, selectedAmenitiesCondominium]);

  useEffect(() => {
    prevAmenitiesListingRef.current = [...selectedAmenitiesListing];
    prevAmenitiesCondominiumRef.current = [...selectedAmenitiesCondominium];
    return () => {
      setSearchValue('');
      setSearchType('')
      setDate([])
    }
  }, [shouldFetchNewData]);

  const handleScroll = () => {
    if (resultsListRef.current && !isLoading && hasMore) {
      const { scrollTop, scrollHeight, clientHeight } = resultsListRef.current;

      // Definindo um limite para telas pequenas, por exemplo, 768px para tablets
      const isSmallScreen = window.innerWidth < 1760;

      // Definindo um valor de ajuste para telas menores
      const smallScreenAdjustment = isSmallScreen ? 100 : 0;

      // Ajustando o cálculo para telas menores
      const isAtBottom = scrollTop + clientHeight >= scrollHeight - smallScreenAdjustment;

      if (isAtBottom) {
        setPage(prevState => {
          const nextPage = prevState + 1;
          getProperties(nextPage);
          return nextPage;
        });
      }
    }
  };


  const filtersNumber = Object.values(filters).reduce((acc, curr) => {
    return curr > 0 ? acc + 1 : acc;
  }, 0);

  const searchPropertiesHeader = () => {
    if (searchValue === addressSearch) return
    if (resultsListRef.current) {
      resultsListRef.current.scrollTop = 0;
      setPage((prevState) => {
        const resetPage = (prevState - prevState) + 1;
        getProperties(resetPage);
        return resetPage
      });
    }
  }

  const filtersAmenities = filtersNumber > 0 ? filtersNumber : 0;
  return (
    <div>

      {breakpoint ? (
        <HeaderMobile />
      ) : (
        <Header setShouldFocus={setShouldFocus} shouldFocus={shouldFocus} paddingHeader getProperties={searchPropertiesHeader} />
      )}
      {/* <MobileNavigation isInView={isInView} /> */}

      {breakpoint ? null : <Location contrain={closed} buildigns={buildingsMap} handleAddress={handleSetAddress} />}
      <S.ResultsContainer closed={closed}>
        {isFetching && breakpoint ? (
          <LoadingMobile />
        ) : isFetching ? (
          <SkeletonResults />
        ) : (
          <>
            <S.ResultsHeader>
              <S.ResultRow>
                <S.ContentResult>
                  <S.InfoSearch>
                    <S.ResultsTitle>
                      <NavLink style={{ textDecoration: 'none', color: '#818181' }} to="/">
                        Inicio{' '}
                      </NavLink>
                      <Icons icon={arrowIcon} width={6} height={11} />
                      <S.SearchResult>{addressSearch}</S.SearchResult>
                    </S.ResultsTitle>
                  </S.InfoSearch>
                </S.ContentResult>
                <S.HeaderResults>
                  {properties.length > 0 ? (
                    <S.Results>
                      {totalProperties} Acomodações para alugar em {addressSearch}{' '}
                    </S.Results>
                  ) : (
                    <S.Results>
                      Nenhuma acomodação encontrada
                    </S.Results>
                  )}
                  <S.FilterColumn>
                    <ToogleValueFee name='valueTotalFee' onChange={setShowTotalWithTax} value={showTotalWithTax} />
                    <S.FilterButton onClick={() => setOpenFilter(!openFilter)}>
                      {' '}
                      {activeAmenitiesFilter.length === 0 && filtersAmenities === 0 ? (
                        <S.ButtonFilter
                        >
                          <Icons icon={filterIcon} height={15} width={18} />
                        </S.ButtonFilter>
                      ) : (
                        <S.ActiveAmenities>
                          <p>{activeAmenitiesFilter.length + filtersAmenities}</p>
                        </S.ActiveAmenities>
                      )}
                    </S.FilterButton>
                  </S.FilterColumn>
                </S.HeaderResults>
                {openFilter && (
                  <Filter amenitiesSelected={verifyHasFilterSelected} onClick={() => setOpenFilter(!openFilter)} />
                )}
              </S.ResultRow>
            </S.ResultsHeader>
            {!isGetPropertiesLoading && properties.length === 0 ? (
              <S.NoResultContainer>
                <S.NoResultImageContainer>
                  <img src={noResult} alt="Nenhum resultado encontrado" />
                </S.NoResultImageContainer>
                <p>
                  Opa! Parece que não encontramos nenhum lugar para sua busca. Mas não se preocupe, vamos tentar
                  novamente juntos!
                </p>

                <Button
                  onClickButton={() => {
                    setExpanded(false);
                    setShouldFocus(true);
                  }}
                  size="large"
                  title="Fazer uma nova busca"
                  type="primary"
                />
              </S.NoResultContainer>
            ) : (
              <S.ResultsList ref={resultsListRef} onScroll={handleScroll}>
                <S.ResultContent>
                  {properties &&
                    properties.map((item: any, index) => {
                      return (
                        <CardResult
                          yogha_pack={item.yogha_pack}
                          cleaningFee={item.cleaningFee}
                          totalWithTax={showTotalWithTax}
                          building_yogha={item.building_yogha}
                          midStay={item.midstay}
                          sumMonth={item.sumValueByDaysAccommodation}
                          condominium={item.condominium}
                          iptu={item.iptu}
                          date={date}
                          id={item.ref_stays}
                          key={item.id + index}
                          acceptsPets
                          bedrooms={item.number_of_bedrooms}
                          city={item.town}
                          price={Number(item.uniqueValue)}
                          gadget={item.activated_at === true && 'Anuncio novo'}
                          images={item.rooms !== undefined ? item.rooms.slice(0, 4) : ['']}
                          mainImage={item.featured_image}
                          maxGuests={item.max_guest_capacity}
                          meters={item.metreage}
                          nameAd={item.name_ad}
                          region={item.building_area}
                          mediaByNight={item.mediaByNight}
                        />
                      )
                    })}
                </S.ResultContent>
                {isLoading && <ItensLoading />}
              </S.ResultsList>
            )}

            <WhatsappButton />
          </>
        )
        }
      </S.ResultsContainer >
      <S.ClosedButton closed={closed} onClick={() => setClosed(!closed)}>
        {closed ? <p style={{ display: 'flex', alignItems: 'center', gap: 8 }}>Mostrar lista {iconclosed}</p> : icon}
      </S.ClosedButton>
    </div >
  );
};
